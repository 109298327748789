<template>
  <div id="user-add">
    <vx-card class="pt-5 pb-5">
      <div class="vx-row relative">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row">
            <div>
              <p class="text-xl font-medium mb-2 mt-4 sm:mt-0"><span v-if="user.firstname">{{ user.firstname }}</span> <span v-if="user.lastname">{{ user.lastname }}</span></p>
            </div>
          </div>
        </div>
      </div>
      <user-form :user="user" @new:user="userNew" @update:user:firstname="firstnameUpdate" @update:user:lastname="lastnameUpdate" />
    </vx-card>
  </div>
</template>

<script>
import UserForm from '@/views/apps/user/user-form/UserForm'

// Store Module
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'

export default {
  components: {
    UserForm
  },
  mixins: [moduleUserManagementMixin],
  metaInfo: {
    title: 'Add new User'
  },
  data () {
    return {
      user: {
        avatar: null,
        groups: []
      },
      avatarFile: null,
      isModified: false
    }
  },
  methods: {
    avatarUpdate (file)  {
      this.avatarFile = file
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.user, 'avatar', {'contentUrl': e.target.result})
          this.isModified = true
        }
        reader.readAsDataURL(file)
      } else {
        this.$set(this.user, 'avatar', null)
      }
    },
    avatarUploaded (mediaObject) {
      this.isModified = true
      this.$set(this.user, 'avatar', mediaObject)
    },
    firstnameUpdate (firstname) {
      this.isModified = true
      this.$set(this.user, 'firstname', firstname)
    },
    lastnameUpdate (lastname) {
      this.isModified = true
      this.$set(this.user, 'lastname', lastname)
    },
    userNew (user) {
      this.isModified = true
      this.$store.dispatch('userManagement/addUser', user)
        .then(() => {
          this.isModified = false
          this.$router.push({'name': 'user-list'})
          this.$vs.notify({
            color: 'success',
            title: this.$t('administration.userform.form.submit.success.notify.title'),
            text: this.$t('administration.userform.form.submit.success.notify.text', { username: user.username })
          })
        })
        .catch((error) => {
          console.error(error)// eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('administration.userform.form.submit.error.notify.title'),
            text: this.$t('administration.userform.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
